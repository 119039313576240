import {Modal} from 'react-bootstrap'
import {ID} from '../../../helpers'
import {TaskModalContentWrapper} from './TaskModalContentWrapper'

type Props = {
  show: boolean
  handleClose: () => void | undefined
  taskId: ID
}

const TaskModal: React.FC<Props> = ({show, handleClose, taskId}) => {
  return (
    <Modal className='px-md-5' id='kt_modal_task' data-backdrop='static' tabIndex={-1} role='dialog' show={show}
      dialogClassName='mw-1000px modal-dialog-centered' aria-hidden='true' onHide={handleClose}>
      <TaskModalContentWrapper id={taskId} handleClose={handleClose} />
    </Modal>
  )
}

export {TaskModal}