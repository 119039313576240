/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {UsersList} from '../../components/UsersList'
import {UsersSymbolGroup} from '../../../../../../../_metronic/partials/widgets/userSelection/UsersSymbolGroup'

interface IconUserModel {
  name: string
  avatar?: string
  color?: string
  initials?: string
}

type Props = {
  participants: Array<IconUserModel>
}

const TaskParticipantsCell: FC<Props> = ({participants}) => {

  return (
    <UsersSymbolGroup users={participants} />
  )
}

export {TaskParticipantsCell}
