// @ts-nocheck
import {Column} from 'react-table'
import {useIntl} from 'react-intl'
import {TaskParticipantsCell} from './TaskParticipantsCell'
import {TaskNameCell} from './TaskNameCell'
import {TaskStatusCell} from './TaskStatusCell'
import {TaskDndHandleCell} from './TaskDndHandleCell'
import {TaskCustomHeader} from './TaskCustomHeader'
import {Task} from '../../core/_models'

const tasksColumns: ReadonlyArray<Column<Task>> = [
  {
    Header: (props) => <TaskCustomHeader tableProps={props} className='pt-0' />,
    id: 'dnd-handle',
    Cell: <TaskDndHandleCell />,
  },
  {
    Header: (props) => <TaskCustomHeader tableProps={props} title={useIntl().formatMessage({id: 'TABLE.GENERAL.LABEL.PARTICIPANTS'})} className='pt-0 min-w-150px w-200px' />,
    id: 'participants',
    Cell: ({...props}) => <TaskParticipantsCell participants={props.data[props.row.index].participants} />,
  },
  {
    Header: (props) => <TaskCustomHeader tableProps={props} title={useIntl().formatMessage({id: 'TABLE.GENERAL.LABEL.NAME'})} className='pt-0 min-w-200px' />,
    id: 'name',
    Cell: ({...props}) => <TaskNameCell rowIndex={props.row.index} columnId={props.column.id} name={props.data[props.row.index].name} />,
  },
  {
    Header: (props) => (
      <TaskCustomHeader tableProps={props} title={useIntl().formatMessage({id: 'TABLE.GENERAL.LABEL.STATUS'})} className='pt-0 text-center' />
    ),
    id: 'status',
    Cell: ({...props}) => <TaskStatusCell rowIndex={props.row.index} columnId={props.column.id} status={props.data[props.row.index].status} />,
  }
]

export {tasksColumns}
