/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState, useEffect} from 'react'
import {Autocomplete, Chip} from '@mui/material'
import {useListView} from '../../core/ListViewProvider'

type StatusProps = {
  name: string
  color: string
}

type Props = {
  status: StatusProps
  rowIndex: number
  columnId: number
}

const STATUS = [
  {
    name: 'Entregue',
    color: 'success'
  },
  {
    name: 'Fazendo',
    color: 'warning'
  },
  {
    name: 'Backlog',
    color: 'primary'
  }
]

const TaskStatusCell: FC<Props> = ({status, rowIndex, columnId}) =>{
  const [value, setValue] = useState<StatusProps | null>(status)
  const {updateCellData} = useListView()

  useEffect(() => {
    setValue(status)
  }, [status])

  const onChange = (e: any, newValue: StatusProps | null) => {
    setValue(newValue)
    if(newValue) {
      updateCellData(rowIndex, columnId, newValue)
    }
  }

  return (
    <div className='d-flex flex-column justify-content-center align-items-center'>
      <Autocomplete
        blurOnSelect={true}
        options={STATUS}
        onChange={onChange}
        classes={{popper: 'w-auto', paper: 'bg-gray-100i', input: `border border-0 rounded-pill fs-7 text-center text-${value?.color} bg-light-${value?.color} mb-0 px-3 py-2 cursor-pointer`}}
        value={value}
        getOptionLabel={(option) => option.name || ""}
        isOptionEqualToValue={(option, value) => option.name === value.name}
        renderInput={(params) => (
          <div className="text-center" ref={params.InputProps.ref}>
            <input type="text" {...params.inputProps} size={value?.name?.length} value={value?.name} />
          </div>
        )}
        renderOption={(props, option) => (
          <li {...props}>
            <Chip classes={{label: `text-${option.color}`, filled: `bg-light-${option.color}`}} label={option.name} clickable={true} />
          </li>
        )}
      />
    </div>
  )
}

export {TaskStatusCell}
