import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardManagerWrapper} from '../pages/dashboard-manager/DashboardManagerWrapper'
import {DashboardUserWrapper} from '../pages/dashboard-user/DashboardUserWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  // const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))
  const TeamsPage = lazy(() => import('../modules/team/TeamsPage'))
  const UsersPage = lazy(() => import('../modules/user/UsersPage'))
  const ProjectsPage = lazy(() => import('../modules/project/ProjectsPage'))
  const TimeTrackingPage = lazy(() => import('../modules/time-tracking/TimeTrackingPage'))
  const ClientsPage = lazy(() => import('../modules/clients/ClientsPage'))
  const SprintsPage = lazy(() => import('../modules/sprints/SprintsPage'))
  const ReportsPage = lazy(() => import('../modules/reports/ReportsPage'))
  const ContractsPage = lazy(() => import('../modules/contracts/ContractsPage'))
  const WikiPage = lazy(() => import('../modules/wiki/WikiPage'))
  const CalendarPage = lazy(() => import('../modules/calendar/CalendarPage'))
  const TicketsPage = lazy(() => import('../modules/tickets/TicketsPage'))
  const ConfigsPage = lazy(() => import('../modules/config/ConfigsPage'))
  const SchedulePage = lazy(() => import('../modules/schedule/SchedulePage'))
  const SquadTasksPage = lazy(() => import('../modules/squad/SquadTasksPage'))
  const ForecastPage = lazy(() => import('../modules/project-forecast/ForecastPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardManagerWrapper />} />
        <Route path='dashboard-user' element={<DashboardUserWrapper />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}
        <Route 
          path='teams/*' 
          element={
            <SuspensedView>
              <TeamsPage />
            </SuspensedView>
          } 
        />
        <Route 
          path='users' 
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          } 
        />
        <Route 
          path='projects/*' 
          element={
            <SuspensedView>
              <ProjectsPage />
            </SuspensedView>
          } 
        />
        <Route
          path='time-tracking/*'
          element={
            <SuspensedView>
              <TimeTrackingPage />
            </SuspensedView>
          }
        />
        <Route
          path='clients/*'
          element={
            <SuspensedView>
              <ClientsPage />
            </SuspensedView>
          }
        />
        <Route
          path='sprints/*'
          element={
            <SuspensedView>
              <SprintsPage />
            </SuspensedView>
          }
        />
        <Route
          path='reports/*'
          element={
            <SuspensedView>
              <ReportsPage />
            </SuspensedView>
          }
        />
        <Route 
          path='contracts/*' 
          element={
            <SuspensedView>
              <ContractsPage />
            </SuspensedView>
          } 
        />
        <Route 
          path='wiki/*' 
          element={
            <SuspensedView>
              <WikiPage />
            </SuspensedView>
          } 
        />
        <Route 
          path='calendar/*' 
          element={
            <SuspensedView>
              <CalendarPage />
            </SuspensedView>
          } 
        />
        <Route 
          path='tickets/*' 
          element={
            <SuspensedView>
              <TicketsPage />
            </SuspensedView>
          } 
        />
        <Route 
          path='configs' 
          element={
            <SuspensedView>
              <ConfigsPage />
            </SuspensedView>
          } 
        />
        <Route 
          path='gantt' 
          element={
            <SuspensedView>
              <SchedulePage />
            </SuspensedView>
          } 
        />
        <Route
          path='squad-tasks/*'
          element={
            <SuspensedView>
              <SquadTasksPage />
            </SuspensedView>
          }
        />
        <Route
          path='project-forecast/*'
          element={
            <SuspensedView>
              <ForecastPage />
            </SuspensedView>
          }
        />
        {/* <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        /> */}
        {/* <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        /> */}
        {/* <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        /> */}
        {/* <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        /> */}
        {/* <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        /> */}
        {/* <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        /> */}
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
