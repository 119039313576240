/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Link} from 'react-router-dom'
// import {useIntl} from 'react-intl'
import {UsersSymbolGroup} from '../../widgets/userSelection/UsersSymbolGroup'

interface IconUserModel {
  name: string
  avatar?: string
  color?: string
  initials?: string
}

type Props = {
  title?: string
  subtitle?: string
  status?: string
  statuscolor?: string
  client?: string
  budgetedHours?: number
  recordedHours?: number
  profitMargin?: number
  deadline?: string
  progressbarcolor?: string
  progressbarwidth?: string
  users: Array<IconUserModel>
}

const ProjectCard: FC<Props> = ({
  title,
  subtitle,
  budgetedHours,
  recordedHours,
  profitMargin,
  status,
  statuscolor,
  deadline,
  users,
  progressbarcolor,
  progressbarwidth,
}) => {
  // const intl = useIntl()

  // const openEditModal = () => {
  //   // setItemIdForUpdate(id)
  // }

  // const deleteItem = useMutation(() => deleteUser(id), {
  //   // 💡 response of the mutation is passed to onSuccess
  //   onSuccess: () => {
  //     // ✅ update detail view directly
  //     queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}`])
  //   },
  // })

  return (
    <Link className='card border border-2 border-gray-200 border-hover' to='/projects/overview'>
      <div className='card-header border-0 py-5'>
          <div className='card-title m-0'>
            <div className='fs-3 fw-bolder text-dark'>{title}</div>
          </div>
          <div className='card-toolbar'>
            <span className={`badge ${statuscolor} fw-bolder me-auto px-4 py-3 `}>{status}</span>
          </div>
        </div>
      <div className='card-body p-5 p-md-5'> 
        {/* <p className='text-gray-400 fw-bold fs-5 mt-1 mb-7'>{subtitle}</p> */}
        <div className='d-flex mb-5 justify-content-between gap-5'>
          <div className='border border-gray-200 border-dashed rounded min-w-100px w-150px  py-3 px-4 mb-3'>
            <div className='fs-6 text-gray-800 fw-bolder'>Horas orçadas:</div>
            <div className='fw-bold text-gray-400'>{budgetedHours && budgetedHours + 'h'}</div>
          </div>
          <div className='border border-gray-200 border-dashed rounded min-w-100px w-150px py-3 px-4 mb-3'>
            <div className='fs-6 text-gray-800 fw-bolder'>Horas lançadas:</div>
            <div className='fw-bold text-gray-400'>{recordedHours && recordedHours + 'h'}</div>
          </div>
        </div>
        <div className='d-flex mb-5 justify-content-between gap-5'>
          <div className='border border-gray-200 border-dashed rounded min-w-100px w-150px py-3 px-4 mb-3'>
            <div className='fs-6 text-gray-800 fw-bolder'>Margem lucro:</div>
            <div className='fw-bold text-gray-400'>{recordedHours && recordedHours + '%'}</div>
          </div>
          <div className='border border-gray-200 border-dashed rounded min-w-100px w-150px py-3 px-4 mb-3'>
            <div className='fs-6 text-gray-800 fw-bolder'>Prazo:</div>
            <div className='fw-bold text-gray-400'>{deadline && deadline}</div>
          </div>
        </div>
        <div
          className='h-4px w-100 bg-light mb-5'
          data-bs-toggle='tooltip'
          title='This project completed'
        >
          <div
            className={`rounded h-4px ${progressbarcolor}`}
            role='progressbar'
            style={{width: `${progressbarwidth}%`}}
          ></div>
        </div>
        <div className='symbol symbol-35px symbol-circle'>
          <UsersSymbolGroup users={users} classes='justify-content-end' />
        </div>
      </div>
    </Link>
  )
}

export {ProjectCard}
