/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useCallback, useState} from 'react'
import {Link} from 'react-router-dom'
import {useIntl} from 'react-intl'
import ptbrLocale from '@fullcalendar/core/locales/pt-br'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import {ProjectCard} from '../../../_metronic/partials/content/cards/ProjectCard'
import {TasksListWrapper} from './components/tasks-list/TasksList'
import {KTSVG, REGISTERED_HOURS} from '../../../_metronic/helpers'
import {AdjustTimeModal} from '../../../_metronic/partials/modals/adjust-time/AdjustTimeModal'
import {DateSelectArg} from '@fullcalendar/core'
import interactionPlugin from '@fullcalendar/interaction' // needed for dayClick
import {OverlayTrigger, Tooltip} from 'react-bootstrap'

const DashboardUserPage: FC = () => {
  const intl = useIntl()
  const [showAdjustTimeModal, setShowAdjustTimeModal] = useState<boolean>(false)

  const participants = [
    {name: 'Rudy Stone', avatar: '/media/avatars/300-1.jpg'},
    {name: 'Lucão', initials: 'L', color: 'primary'},
  ]

  const toolbar = {
    left: 'prevYear,prev,next,nextYear today',
    center: 'title',
    right: 'dayGridMonth,dayGridWeek,dayGridDay',
  }
  let eventGuid = 0
  const createEventId = () => String(eventGuid++)

  const handleDateSelect = useCallback((selectInfo: DateSelectArg) => {
    let title = prompt('Insira o título do evento')?.trim()
    let calendarApi = selectInfo.view.calendar
    calendarApi.unselect()
    if (title) {
      calendarApi.addEvent({
        id: createEventId(),
        title,
        start: selectInfo.startStr,
        end: selectInfo.endStr,
        allDay: selectInfo.allDay,
      })
    }
  }, [])

  return (
    <>
      {/* START HEADER */}
      <div className='container'>
        <div className='row align-items-end justify-content-between bg-body pt-10 pb-15 px-3 px-md-5 mb-6 rounded'>
          <div className='col-md-8'>
            <div className='d-flex align-items-center mb-4'>
              <div className='fw-semi-bold fs-2 me-4'>
                {intl.formatMessage({id: 'DASHBOARD.LABEL.WORKPLACE'})}
              </div>
              <span className='text-gray-600'>
                {intl.formatMessage({id: 'DASHBOARD.LABEL.FEATURED_OF_THE_DAY'})}
              </span>
            </div>
            <div className='d-flex align-items-center'>
              <div className='symbol symbol-circle symbol-60px overflow-hidden me-3'>
                <div className='symbol-label'>
                  <img src='/media/avatars/300-12.jpg' alt='ADS Latin' className='w-100' />
                </div>
              </div>
              <div className='d-flex flex-column'>
                <p className='text-gray-900 fw-bold fs-4 mb-1'>Bom dia, Alice!</p>
                <span className='text-gray-600'>
                  Hoje inicia o ciclo dos clientes ABC e XYZ. Em 07 dias, será a entrega do layout
                  do site ABC.
                </span>
              </div>
            </div>
          </div>
          <div className='col-md-4 d-flex align-items-center justify-content-between justify-content-md-end mt-10 mt-md-0'>
            <div className='text-end border-end border-gray-300 pe-5 me-10'>
              <span className='text-gray-600'>
                {intl.formatMessage({id: 'DASHBOARD.LABEL.PROJECTS_I_AM_IN'})}
              </span>
              <div className='fs-2'>256</div>
            </div>
            <div className='text-end'>
              <span className='text-gray-600'>
                {intl.formatMessage({id: 'DASHBOARD.LABEL.TASKS_FOR_ME'})}
              </span>
              <div className='fs-2'>7</div>
            </div>
          </div>
        </div>
      </div>
      {/* END HEADER */}
      <div className='container pt-8 pb-15'>
        <div className='row mb-10'>
          <div className='col-12'>
            <div className='fs-2x fw-bold'>
              {intl.formatMessage({id: 'DASHBOARD.LABEL.TASKS_INDICATORS'})}
            </div>
          </div>
        </div>
        <div className='row bg-body pt-10 pb-4 px-3 px-md-5 mb-6 rounded'>
          <div className='col-lg-6 justify-content-center align-items-center ps-lg-10 mt-md-10 mt-lg-0 mb-8 mb-md-0'>
            <div className='row g-3 g-lg-6'>
              <div className='col-4'>
                <OverlayTrigger
                  key={1}
                  placement='bottom'
                  overlay={
                    <Tooltip id='tooltip-user-name'>
                      {intl.formatMessage({id: 'DASHBOARD.LABEL.OPEN_TO_ME'})}
                    </Tooltip>
                  }
                >
                  <div className='bg-gray-100 bg-opacity-70 rounded-2 px-6 py-5'>
                    <div className='symbol symbol-30px me-5 mb-8'>
                      <KTSVG
                        path='media/icons/duotune/general/gen013.svg'
                        className='svg-icon svg-icon-1 svg-icon-primary'
                      />
                    </div>
                    <div className='m-0 py-md-3'>
                      <span className='text-gray-700 fw-bolder d-block fs-2qx lh-1 ls-n1 mb-1'>
                        37
                      </span>
                      <span className='text-gray-500 fw-semibold fs-6 d-none d-md-block'>
                        {intl.formatMessage({id: 'DASHBOARD.LABEL.OPEN_TO_ME'})}
                      </span>
                    </div>
                  </div>
                </OverlayTrigger>
              </div>
              <div className='col-4'>
                <OverlayTrigger
                  key={2}
                  placement='bottom'
                  overlay={
                    <Tooltip id='tooltip-user-name'>
                      {intl.formatMessage({id: 'DASHBOARD.LABEL.FINISHED_ON_TIME'})}
                    </Tooltip>
                  }
                >
                  <div className='bg-gray-100 bg-opacity-70 rounded-2 px-6 py-5'>
                    <div className='symbol symbol-30px me-5 mb-8'>
                      <KTSVG
                        path='media/icons/duotune/general/gen020.svg'
                        className='svg-icon svg-icon-1 svg-icon-primary'
                      />
                    </div>
                    <div className='m-0 py-md-3'>
                      <span className='text-gray-700 fw-bolder d-block fs-2qx lh-1 ls-n1 mb-1'>
                        60
                      </span>
                      <span className='text-gray-500 fw-semibold fs-6 d-none d-md-block'>
                        {intl.formatMessage({id: 'DASHBOARD.LABEL.FINISHED_ON_TIME'})}
                      </span>
                    </div>
                  </div>
                </OverlayTrigger>
              </div>
              <div className='col-4'>
                <OverlayTrigger
                  key={3}
                  placement='bottom'
                  overlay={
                    <Tooltip id='tooltip-user-name'>
                      {intl.formatMessage({id: 'DASHBOARD.LABEL.NOT_FINISHED'})}
                    </Tooltip>
                  }
                >
                  <div className='bg-gray-100 bg-opacity-70 rounded-2 px-6 py-5'>
                    <div className='symbol symbol-30px me-5 mb-8'>
                      <KTSVG
                        path='media/icons/duotune/general/gen040.svg'
                        className='svg-icon svg-icon-1 svg-icon-primary'
                      />
                    </div>
                    <div className='m-0'>
                      <span className='text-gray-700 fw-bolder d-block fs-2qx lh-1 ls-n1 mb-1'>
                        47
                      </span>
                      <span className='text-gray-500 fw-semibold fs-6 d-none d-md-block'>
                        {intl.formatMessage({id: 'DASHBOARD.LABEL.NOT_FINISHED'})}
                      </span>
                    </div>
                  </div>
                </OverlayTrigger>
              </div>
            </div>
          </div>
          <div className='col-lg-6 ps-lg-10 mt-md-10 mt-lg-0 mb-8 mb-md-0'>
            <div className='d-flex justify-content-between align-items-center mb-10'>
              <div className='fs-2 fw-bold'>
                {intl.formatMessage({id: 'DASHBOARD.LABEL.REGISTERED_HOURS_IN_TASKS'})}
              </div>
              <div className='d-flex align-items-center pe-md-0'>
                <label className='label-form me-3 fs-5'>
                  {intl.formatMessage({id: 'PROJECT.LABEL.PERIOD'})}
                </label>
                <select className='form-select form-select-sm'>
                  <option>Última semana</option>
                  <option value='1'>12/12 a 16/12</option>
                  <option value='2'>05/12 a 09/12</option>
                  <option value='3'>28/11 a 02/12</option>
                </select>
              </div>
            </div>
            <div className='d-flex justify-content-between overflow-scroll'>
              {REGISTERED_HOURS.map((registered_hour, i) => (
                <div className='text-center w-fit-content me-5 me-md-0' key={i}>
                  <span className='fs-7 white-space-nowrap'>{registered_hour.day_of_week}</span>
                  <div
                    className='w-55px h-75px bg-gray-300 position-relative d-flex align-items-end rounded'
                    data-bs-toggle='tooltip'
                    title='This project completed'
                  >
                    <div
                      className={`bg-success rounded w-55px`}
                      role='progressbar'
                      style={{height: `${registered_hour.worked_hours_in_percentage}%`}}
                    ></div>
                    <div className='text-center text-white position-absolute end-0 bottom-0px start-0 pb-1'>
                      {registered_hour.worked_hours}
                    </div>
                  </div>
                  <a
                    href='#'
                    onClick={(e) => {
                      e.preventDefault()
                      setShowAdjustTimeModal(true)
                    }}
                    className='fs-8 text-primary'
                  >
                    {intl.formatMessage({id: 'GENERAL.ADJUST'})}
                  </a>
                </div>
              ))}
            </div>
            <div className='d-flex justify-content-between align-items-center pt-10'>
              <div className='d-flex align-items-center'>
                <div className='text-gray-600 fs-4 fw-bold me-5'>
                  <span className='text-danger'>39:12h</span> de 42h
                </div>
                <div className='text-success fw-bold'>92%</div>
              </div>
              <Link
                to={'#'}
                className='btn btn-outline border-primary text-primary btn-sm w-fit-content py-2 px-3'
              >
                {intl.formatMessage({id: 'DASHBOARD.LABEL.SEE_MY_HISTORIC'})}
              </Link>
            </div>
          </div>
        </div>
        <div className='row bg-body pt-10 pb-4 px-3 px-md-5 mb-6 rounded'>
          <div className='fs-2 fw-bold mb-8'>
            {intl.formatMessage({id: 'DASHBOARD.LABEL.MYSCHEDULE'})}
          </div>
          <FullCalendar
            plugins={[dayGridPlugin, interactionPlugin]}
            initialView='dayGridWeek'
            locale={ptbrLocale}
            selectable={true}
            select={handleDateSelect}
            editable={true}
            dayMaxEvents={true}
            headerToolbar={toolbar}
            height='auto'
            themeSystem='bootstrap5'
            events={[
              {
                title: 'The Title',
                start: '2023-01-13',
              },
              {
                title: 'Other Title',
                start: '2023-01-10',
              },
            ]}
          />
        </div>
        <div className='row bg-body pt-10 pb-4 px-3 px-md-5 mb-6 rounded'>
          <TasksListWrapper />
        </div>
        <div className='row pt-10 pb-4 px-3 px-md-5 mb-6'>
          <div className='d-flex justify-content-between align-items-center mb-10'>
            <div className='fs-2 fw-bold'>
              {intl.formatMessage({id: 'DASHBOARD.LABEL.PROJECTS_I_LEAD'})}
            </div>
            <div className='d-flex align-items-center position-relative me-5'>
              <span className='svg-icon svg-icon-1 position-absolute ms-3'>
                <svg
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                  className='mh-50px'
                >
                  <rect
                    opacity='0.5'
                    x='17.0365'
                    y='15.1223'
                    width='8.15546'
                    height='2'
                    rx='1'
                    transform='rotate(45 17.0365 15.1223)'
                    fill='currentColor'
                  ></rect>
                  <path
                    d='M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z'
                    fill='currentColor'
                  ></path>
                </svg>
              </span>
              <input
                type='text'
                className='form-control form-control-sm form-control-solid w-md-250px ps-11 bg-transparent'
                placeholder={intl.formatMessage({id: 'TOOLBAR.SEARCH_PROJECT'})}
                // value=''
              />
            </div>
          </div>
          <div className='col-md-6 col-lg-4 mb-8'>
            <ProjectCard
              title='Playwav'
              budgetedHours={300}
              recordedHours={124}
              profitMargin={52}
              deadline='55 de 120 dias'
              users={participants}
              status='Pendente'
              statuscolor='badge-light-primary'
              progressbarcolor='bg-primary'
              progressbarwidth='90'
            />
          </div>
          <div className='col-md-6 col-lg-4 mb-8'>
            <ProjectCard
              title='Share'
              budgetedHours={300}
              recordedHours={124}
              profitMargin={52}
              deadline='55 de 120 dias'
              users={participants}
              status='Atrasado'
              statuscolor='badge-light-danger'
              progressbarcolor='bg-danger'
              progressbarwidth='20'
            />
          </div>
          <div className='col-md-6 col-lg-4 mb-8'>
            <ProjectCard
              title='Empório do Presente'
              budgetedHours={300}
              recordedHours={124}
              profitMargin={52}
              deadline='100 de 120 dias'
              users={participants}
              status='Concluído'
              statuscolor='badge-light-success'
              progressbarcolor='bg-info'
              progressbarwidth='30'
            />
          </div>
          <div className='col-md-6 col-lg-4 mb-8'>
            <ProjectCard
              title='Verde'
              budgetedHours={300}
              recordedHours={77}
              profitMargin={52}
              deadline='30 de 120 dias'
              users={participants}
              status='Concluído'
              statuscolor='badge-light-success'
              progressbarcolor='bg-primary'
              progressbarwidth='45'
            />
          </div>
          <div className='col-md-6 col-lg-4 mb-8'>
            <ProjectCard
              title='Persoona'
              budgetedHours={300}
              recordedHours={29}
              profitMargin={52}
              deadline='20 de 120 dias'
              users={participants}
              status='Concluído'
              statuscolor='badge-light-success'
              progressbarcolor='bg-success'
              progressbarwidth='100'
            />
          </div>
          <div className='col-md-6 col-lg-4 mb-8'>
            <ProjectCard
              title='Unimed'
              budgetedHours={300}
              recordedHours={100}
              profitMargin={52}
              status='Em progresso'
              statuscolor='badge-light-primary'
              deadline='55 de 120 dias'
              users={participants}
              progressbarcolor='bg-danger'
              progressbarwidth='20'
            />
          </div>
          
        </div>
      </div>
      <AdjustTimeModal
        show={showAdjustTimeModal}
        handleClose={() => setShowAdjustTimeModal(false)}
      />
    </>
  )
}

const DashboardUserWrapper: FC = () => {
  return (
    <>
      <DashboardUserPage />
    </>
  )
}

export {DashboardUserWrapper}
