import {FC, useState, InputHTMLAttributes} from "react"
import {KTSVG} from "../../../helpers"
import clsx from 'clsx'
import {useIntl} from 'react-intl'
import {Task} from "./core/_models"
import {UsersSymbolGroup} from "../../widgets/userSelection/UsersSymbolGroup"

type Props = {
    isTaskLoading: boolean
    task: Task
    handleClose: () => void | undefined
}

interface checkProps extends InputHTMLAttributes<HTMLInputElement> {
    content: string,
    check: boolean
}
  
interface responsibeProps extends InputHTMLAttributes<HTMLInputElement> {
    image: string,
    name: string
}
  
const participants = [
    {
      name: "Pablo Germano",
      color: "primary",
      initials: "PG"
    },
    {
      name: "André Brunelli",
      avatar: "/media/avatars/300-5.jpg"
    },
    {
      name: "Pedro Vinicius",
      avatar: "/media/avatars/300-7.jpg"
    },
    {
        name: "Angela Sousa",
        avatar: "/media/avatars/300-8.jpg"
      }
]

const TaskModalContent: FC<Props> = ({handleClose}) => {
    const intl = useIntl()
    const [checks, setChecks] = useState<Array<checkProps>>([])
    const [responsible, setResponsible] = useState<Array<responsibeProps>>([])
    const [taskName, setTaskName] = useState<string>('')

    const addCheck = () => {
        setChecks([...checks, {content: '', check: false}])
    }

    const addResponsible = (user:string) => {
        setResponsible([...responsible, {image: '', name: user}])
    }

    const removeCheck = (key:number) => {
        setChecks(checks.filter((value, index) => key!== index));
    }
  
    const removeResponsible = (key:number) => {
        setResponsible(responsible.filter((value, index) => key!== index));
    }
    
    return (
        <div className='modal-content'>
            <div className='modal-header border-0'>
                <div className="col-md-8">
                {/* begin::Input */}
                <input onChange={(e) => setTaskName(e.target.value)} placeholder={intl.formatMessage({id: 'FORM.INPUT.NAME.TASK_TITLE'})} type='text' name='name'
                    className={clsx( 'form-control py-2 bg-transparent border-0 fs-1' )} autoComplete='off' />
                {/* end::Input */}
                </div>
                <div className="col-md-3 d-none d-md-flex justify-content-end pe-4">
                    <UsersSymbolGroup users={participants} />
                </div>
                <div className="col-md-1 d-flex justify-content-end align-items-center">
                    <div className="actions pt-1">
                        <a href='#' className='text-hover-primary' data-kt-menu-trigger='click' data-kt-menu-placement='bottom-end'>
                            <i className="bi bi-list-ul fs-1 text-gray-800 d-flex align-items-center"></i>
                        </a>
                        {/* begin::Menu */}
                        <div
                        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
                        data-kt-menu='true'>
                            {/* begin::Menu item */}
                            <div className='menu-item px-3'>
                                <a className='menu-link px-3' onClick={()=> {}}>
                                {intl.formatMessage({id: 'TABLE.GENERAL.LABEL.FINIS_TASK'})}
                                </a>
                            </div>
                            {/* end::Menu item */}
                            {/* begin::Menu item */}
                            <div className='menu-item px-3'>
                                <a className='menu-link px-3' data-kt-user-table-filter='delete_row' onClick={async ()=> {}}
                                >
                                {intl.formatMessage({id: 'TABLE.GENERAL.LABEL.CLONE_TASK'})}
                                </a>
                            </div>
                            {/* end::Menu item */}
                            {/* begin::Menu item */}
                            <div className='menu-item px-3'>
                                <a className='menu-link px-3' data-kt-user-table-filter='delete_row' onClick={async ()=> {}}
                                >
                                {intl.formatMessage({id: 'TABLE.GENERAL.LABEL.DELETE'})}
                                </a>
                            </div>
                            {/* end::Menu item */}
                        </div>
                        {/* end::Menu */}
                    </div>
                    <button className='btn btn-sm btn-icon btn-active-color-primary h-auto ms-4' onClick={handleClose}>
                      <KTSVG className='svg-icon-2x svg-icon-gray-800' path='/media/icons/duotune/arrows/arr061.svg' />
                    </button>
                </div>
            </div>
            <div className='modal-body d-flex flex-column flex-lg-row justify-content-between'>
                <div className="col-lg-8 pe-5 pb-5 pb-lg-0 mb-15 mb-lg-0 border-bottom border-bottom-lg-0">
                <ul className="nav nav-stretch nav-line-tabs fw-bold border-transparent flex-nowrap " role="tablist">
                    <li className="nav-item">
                    <a className="nav-link cursor-pointer text-gray-900 active" data-bs-toggle="tab" href="#kt_task_description_pane">{intl.formatMessage({id: 'TASK.TABLE.DESCRIPTION'})}</a>
                    </li>
                    <li className="nav-item">
                    <a className="nav-link cursor-pointer text-gray-900" data-bs-toggle="tab" href="#kt_task_attachments_pane">{intl.formatMessage({id: 'TASK.TABLE.ATTACHMENTS'})}</a>
                    </li>
                    <li className="nav-item">
                    <a className="nav-link cursor-pointer text-gray-900" data-bs-toggle="tab" href="#kt_task_checklist_pane">{intl.formatMessage({id: 'TASK.TABLE.CHECKLIST'})}</a>
                    </li>
                    <li className="nav-item">
                    <a className="nav-link cursor-pointer text-gray-900" data-bs-toggle="tab" href="#kt_task_responsible_sequence_pane">{intl.formatMessage({id: 'TASK.TABLE.RESPONSIBLE_SEQUENCE'})}</a>
                    </li>
                </ul>
                <div className="tab-content pt-3">
                    <div id="kt_task_description_pane" className="tab-pane fade show active">
                    <textarea 
                        className="form-control border-0 fw-normal" 
                        id="description" 
                        name="description" 
                        style={{resize: 'none'}} 
                        rows={8}
                        placeholder={intl.formatMessage({id: 'TASK.TABLE.DESCRIPTION'})}
                    >
                    </textarea>
                    {/* <div className="mb-10">
                        <span className="d-block fw-bold text-dark me-1 mb-3">
                        {intl.formatMessage({id: 'GENERAL.PROGRESS'})}
                        </span>
                        <div className="d-flex align-items-center">
                        <i className="bi bi-emoji-frown me-3"></i>
                        <div className="col-9">
                            <div
                            className='h-5px w-100 bg-light'
                            data-bs-toggle='tooltip'
                            title='This project completed'
                            >
                            <div
                            className={`bg-primary rounded h-5px`}
                            role='progressbar'
                            style={{width: `0%`}}
                            ></div>
                            </div>
                        </div>
                        <i className="bi bi-emoji-smile ms-3"></i>
                        </div>
                    </div> */}
                    <div className="d-flex flex-column col-lg-10">
                        <span className="d-block fw-bold text-dark me-1 mb-3">
                        {intl.formatMessage({id: 'TASK.LABEL.WEIGHT'})}
                        </span>
                        <div className="btn-group mb-4" role="group" aria-label="Basic checkbox toggle button group">
                        <input type="checkbox" className="btn-check" id="primary" />
                        <label  className="fs-6 btn btn-outline btn-outline-primary rounded fw-normal py-1 px-0" htmlFor="primary">{intl.formatMessage({id: 'TASK.LABEL.WEIGHT_NAME_PRIMARY'}, {weight: 40})}</label>

                        <input type="checkbox" className="btn-check" id="secondary-20" />
                        <label className="fs-6 btn btn-outline btn-outline-primary rounded fw-normal py-1 px-0" htmlFor="secondary-20">{intl.formatMessage({id: 'TASK.LABEL.WEIGHT_NAME_SECONDARY'}, {weight: 20})}</label>

                        <input type="checkbox" className="btn-check" id="secondary-10" />
                        <label className="fs-6 btn btn-outline btn-outline-primary rounded fw-normal py-1 px-0" htmlFor="secondary-10">{intl.formatMessage({id: 'TASK.LABEL.WEIGHT_NAME_SECONDARY'}, {weight: 10})}</label>
                        
                        <input type="checkbox" className="btn-check" id="secondary-5" />
                        <label className="fs-6 btn btn-outline btn-outline-primary rounded fw-normal py-1 px-0" htmlFor="secondary-5">{intl.formatMessage({id: 'TASK.LABEL.WEIGHT_NAME_SECONDARY'}, {weight: 5})}</label>
                        </div>
                    </div>
                    </div>
                    <div id="kt_task_attachments_pane" className="tab-pane fade">
                    <div className="pt-6">
                        <a href="#" className="d-block text-gray-800 text-hover-primary fs-6 mb-4">Arquivo1.pdf</a>
                        <a href="#" className="d-block text-gray-800 text-hover-primary fs-6 mb-4">Arquivo2.pdf</a>
                        <a href="#" className="d-block text-gray-800 text-hover-primary fs-6 mb-4">Arquivo3.pdf</a>
                    </div>
                    </div>
                    <div id="kt_task_checklist_pane" className="tab-pane fade">
                    <div className="checklist pt-6">
                        {
                        checks.map((check, key) => (
                            <div className="check d-flex align-items-center mb-4" key={key}>
                            <input 
                                className="form-check-input" 
                                type="checkbox" 
                                value="" 
                                id={key.toString()}
                                onChange={(e) => setChecks(checks.filter((c, index) => {
                                if(key === index) {
                                    check.check = e.target.checked
                                }
                                return c
                                }))}
                            />
                            <input 
                                className="form-control border-0 rounded-0 bg-gray-100 bg-focus-gray-100 p-0 ps-2 py-2 ms-4" 
                                type="text" 
                                placeholder="To do" 
                                value={check.content}
                                onChange={e => setChecks(checks.filter((c, index) => {
                                if(key === index) {
                                    c.content = e.target.value
                                }
                                return c
                                }))}
                            ></input>
                            <button 
                                onClick={() => removeCheck(key)}
                                className="btn p-0 ms-5"
                            >
                                <i className="bi bi-trash3-fill"></i>
                            </button>
                            </div>
                        ))
                        }
                        <button 
                        onClick={addCheck}
                        className="btn text-bold d-flex align-items-center p-0 mt-10"
                        >
                        <div className="d-flex align-items-center justify-content-center w-15px h-15px bg-gray-900 me-3">
                            <i className="bi bi-plus text-gray-100 fs-5 p-0"></i> 
                        </div>
                        {intl.formatMessage({id: 'GENERAL.ADD_ITEM'})}
                        </button>
                    </div>
                    </div>
                    <div id="kt_task_responsible_sequence_pane" className="tab-pane fade">
                    <div className="pt-6">
                        <div className="timeline">
                        {
                        responsible.map((responsible, key) => (
                            <div className="timeline-item">
                            <div className="timeline-line w-40px"></div>
                            <div className="timeline-icon symbol symbol-circle symbol-40px overflow-hidden me-4">
                                <div className="symbol-label bg-light">
                                <span className="svg-icon svg-icon-2 svg-icon-gray-500">
                                    <img src="/media/avatars/300-6.jpg" alt="Pic" className="w-100" />
                                </span>
                                </div>
                            </div>
                            <div className="timeline-content mb-10 mt-2 d-flex align-items-center justify-content-between">
                                <div className="overflow-auto pe-3">
                                <div className="fs-5 fw-semibold mb-2">{responsible.name}</div>
                                </div>
                                <button 
                                onClick={() => removeResponsible(key)}
                                className="btn p-0 ms-5"
                                >
                                <i className="bi bi-x-square-fill"></i>
                                </button>
                            </div>
                            </div>
                        ))
                        }
                        </div>
                        <div className="d-flex align-items-center">
                        <div className="d-flex align-items-center justify-content-center w-15px h-15px bg-gray-900 me-3">
                            <i className="bi bi-plus text-gray-100 fs-5 p-0"></i> 
                        </div>
                        <div className="form-group">
                            <select 
                            className="form-select form-select-sm py-2 pe-8" 
                            onChange={(e) => addResponsible(e.target.value)}
                            >
                            <option>{intl.formatMessage({id: 'GENERAL.ADD_RESPONSIBLE'})}</option>
                            <option value="Alice Garcia">Alice Garcia</option>
                            <option value="André Brunelli">André Brunelli</option>
                            <option value="Hector Lutero">Hector Lutero</option>
                            </select>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                <div className="col-lg-4 border-start-lg ps-lg-10">
                <div className="border-bottom pb-8">
                    <div className="d-flex mb-10">
                    <span className="fw-bold text-dark me-1">
                    {intl.formatMessage({id: 'TASK.LABEL.ESTIMATED_DEADLINE'})}
                    </span>
                    <span className="text-dark me-2">
                        19/07
                    </span>
                    </div>
                    <div className="mb-6">
                    <span className="fw-bold text-dark me-1">
                    {intl.formatMessage({id: 'TASK.LABEL.TOTAL_WORKED'})}
                    </span>
                    <div className="d-flex align-items-center">
                        <div className="col-9">
                            <div
                                className='h-7px w-100 bg-light'
                                data-bs-toggle='tooltip'
                                title='This project completed'
                            >
                                <div
                                className={`bg-primary rounded h-7px`}
                                role='progressbar'
                                style={{width: `1%`}}
                                ></div>
                            </div>
                        </div>
                        <div className="col-3 text-start ps-2">
                            <div className="text-gray-900">0</div>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="pt-10">
                    <div className="d-flex mb-8">
                        <span className="fw-bold text-dark me-1">
                            {intl.formatMessage({id: 'TASK.LABEL.CLIENT'})}
                        </span>
                        <span className="text-dark me-2">
                            {intl.formatMessage({id: 'TASK.LABEL.SELECT_CLIENT'})}
                        </span>
                        <a href="#">
                            <i className="bi bi-pencil"></i>
                        </a>
                    </div>
                    <div className="d-flex">
                        <span className="fw-bold text-dark me-1">
                            {intl.formatMessage({id: 'TASK.LABEL.PROJECT'})}
                        </span>
                        <span className="text-dark me-2">
                            {intl.formatMessage({id: 'TASK.LABEL.SELECT_PROJECT'})}
                        </span>
                        <a href="#">
                            <i className="bi bi-pencil"></i>
                        </a>
                    </div>
                </div>
                <div className="text-center mt-20">
                    <a 
                        onClick={() => {}}
                        className="btn btn-primary btn-sm py-2 px-8" 
                        href="#">
                    {intl.formatMessage({id: 'GENERAL.CREATE_TASK'})}
                    </a>
                </div>
                </div>
            </div>
        </div>
    )
}

export {TaskModalContent}