import {FC} from 'react'
import {Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import { useIntl } from 'react-intl'

const Error404: FC = () => {
  const intl = useIntl();
  return (
    <>
      {/* begin::Title */}
      <h1 className='fw-bolder fs-2hx text-gray-900 mb-4'>Oops!</h1>
      {/* end::Title */}
      {/* We can't find that page. */}
      {/* begin::Text */}
      <div className='fw-semibold fs-6 text-gray-500 mb-7'>{intl.formatMessage({id: 'GENERAL.TEXT.ERROR404'})}</div>
      {/* end::Text */}

      {/* begin::Illustration */}
      <div className='mb-10'>
        <img
          src={toAbsoluteUrl('/media/logos/taskrush.svg')}
          className='mw-100 mh-200px theme-light-show'
          alt=''
        />
        <img
          src={toAbsoluteUrl('/media/logos/taskrush.svg')}
          className='mw-100 mh-200px theme-dark-show'
          alt=''
        />
      </div>
      {/* end::Illustration */}

      {/* begin::Link */}
      <div className='mb-0'>
        <Link to='/dashboard' className='btn btn-sm btn-primary'>
        {intl.formatMessage({id: 'GENERAL.RETURN'})}
          {/* Return Home */}
        </Link>
      </div>
      {/* end::Link */}
    </>
  )
}

export {Error404}
