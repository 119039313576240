import {useMemo, useState, useEffect} from 'react'
import {useTable, ColumnInstance, Row} from 'react-table'
import {
  DndContext, 
  closestCorners,
  MouseSensor,
  useSensor,
  useSensors,
  DragEndEvent,
} from '@dnd-kit/core'
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import {
  restrictToVerticalAxis
} from '@dnd-kit/modifiers'
import {CustomHeaderColumn} from './columns/CustomHeaderColumn'
import {CustomRow} from './columns/CustomRow'
import {useQueryResponseData, useQueryResponseLoading} from '../core/QueryResponseProvider'
import {useListView} from '../core/ListViewProvider'
import {tasksColumns} from './columns/_columns'
import {Task} from '../core/_models'
import {TasksListLoading} from '../components/loading/TasksListLoading'
import {TasksListPagination} from '../components/pagination/TasksListPagination'
import {KTCardBody} from '../../../../../../_metronic/helpers'

const TasksTable = () => {
  const {tasks, setTasks, updateCellData, addRow, removeRow} = useListView()
  const isLoading = useQueryResponseLoading()
  const data = useMemo(() => tasks, [tasks])
  const columns = useMemo(() => tasksColumns, [])

  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  })

  const items = useMemo(() => tasks?.reduce((filtered: string[], option: Task) => {
    if(option.id !== null && option.id !== undefined) {
      var id = option.id.toString()
      filtered.push(id)
    }
    return filtered
  }, []), [tasks])

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
  );

  const handleDragEnd = (event: DragEndEvent) => {
    const {active, over} = event;
    if (over && active.id !== over?.id && setTasks) {
      setTasks((tasks) => {
        const oldIndex = items.indexOf(active.id.toString());
        const newIndex = items.indexOf(over.id.toString());
        return arrayMove(tasks, oldIndex, newIndex);
      })
    }
  }

  return (
    <KTCardBody className='py-4'>
      <div className='table-responsive'>
        <DndContext
          sensors={sensors}
          collisionDetection={closestCorners}
          onDragEnd={handleDragEnd}
          modifiers={[restrictToVerticalAxis]}
        >
          <table
            id='kt_table_tasks'
            className='table align-middle table-row-dashed fs-6 gy-4 dataTable no-footer tab-pane fade show active'
            {...getTableProps()}
          >
            <thead>
              <tr className='text-start text-muted fw-bolder fs-7 gs-0 border-bottom-0'>
                {headers.map((column: ColumnInstance<Task>) => (
                  <CustomHeaderColumn key={column.id} column={column} />
                ))}
              </tr>
            </thead>
            <tbody className='text-gray-600' {...getTableBodyProps()}>
              <SortableContext 
                items={items}
                strategy={verticalListSortingStrategy}
              >
                {rows.length > 0 ? (
                  rows.map((row: Row<Task>, i) => {
                    prepareRow(row)
                    return <CustomRow row={row} key={row.original.id} />
                  })
                  ) : (
                  <tr>
                    <td colSpan={7}>
                      <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                        No matching records found
                      </div>
                    </td>
                  </tr>
                )}
                <tr role="row" onClick={addRow}>
                  <td colSpan={4} className='bg-hover-light px-4 fw-semi-bold'>
                    Adicionar tarefa
                  </td>
                </tr>
              </SortableContext>
            </tbody>
          </table>
        </DndContext>
      </div>
      <TasksListPagination />
      {isLoading && <TasksListLoading />}
    </KTCardBody>
  )
}

export {TasksTable}
