// @ts-nocheck
import clsx from 'clsx'
import {FC} from 'react'
import {Row} from 'react-table'
import {useSortable} from '@dnd-kit/sortable'
import {CSS} from '@dnd-kit/utilities'
import {Task} from '../../core/_models'
import {useListView} from '../../core/ListViewProvider'

type Props = {
  row: Row<Task>
}

const CustomRow: FC<Props> = ({row}) => {

  const {setTaskIdForUpdate} = useListView()

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({id: row.original.id?.toString()})

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <tr 
      {...row.getRowProps()} 
      ref={setNodeRef} 
      style={style}
      className="bg-hover-light"
    >
      {row.cells.map((cell, i) => {
        if(cell.column.id === 'dnd-handle') {
          return (
            <td
              {...cell.getCellProps()}
              {...attributes}
              {...listeners}
              key={i}
            >
              {cell.render('Cell')}
            </td>
          )
        }
        return (
          <td
            {...cell.getCellProps()}
            className={clsx({'text-end min-w-100px': cell.column.id === 'actions'})}
            key={i}
          >
            {cell.render('Cell')}
          </td>
        )
      })}
    </tr>
  )
} 

export {CustomRow}
