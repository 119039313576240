/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState, useEffect, ChangeEvent, KeyboardEvent, useRef} from 'react'
import {useListView} from '../../core/ListViewProvider'

type Props = {
  name: string
  rowIndex: number
  columnId: number
}

const TaskNameCell: FC<Props> = ({name, rowIndex, columnId}) => {
  const [value, setValue] = useState<string>('')
  const ref = useRef<HTMLInputElement>(null);
  const {updateCellData, addRow, removeRow} = useListView()
  useEffect(() => {
    if(ref.current && name === '') {
      ref.current.focus()
    }
  }, [ref.current])

  useEffect(() => {
    setValue(name)
  }, [name])

  const validateRow = (isEnterPress: boolean = false) => {
    if(name !== '' && value === '') {
      setValue(name)
      return
    }
    
    if(name === '' && value === '') removeRow()
    
    if(name !== value) updateCellData(rowIndex, columnId, value)
    
    if(value !== '' && isEnterPress) addRow()
  }

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
  }

  const onBlur = () => {
    validateRow()
  }

  const onKeyDow = (e: KeyboardEvent<HTMLInputElement>) => {
    if(e.key === 'Enter') {
      validateRow(true)
    }
  }

  return (
    <div className='d-flex flex-column justify-content-center'>
      <input 
        value={value} 
        onChange={onChange} 
        onBlur={onBlur} 
        onKeyDown={onKeyDow}
        ref={ref}
        className="fw-normal form-control py-1 bg-transparent border-1 border-transparent border-hover-secondary"
      />
    </div>
  )
} 

export {TaskNameCell}
