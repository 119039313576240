import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../../_metronic/helpers'
import {Task, TasksQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_THEME_API_URL
const TASK_URL = `${API_URL}/task`
const GET_TASKS_URL = `${API_URL}/tasks/query`

const tasks = {
  "data": [
    {
      "id": 1,
      "name": "Implementar tela de login",
      "status": {
        "name": "Entregue",
        "color": "success"
      },
      "participants": [
        {name: 'Emma Smith', avatar: '/media/avatars/300-6.jpg'},
        {name: 'Rudy Stone', avatar: '/media/avatars/300-1.jpg'},
        {name: 'André Brunelli', initials: 'A', color: 'primary'},
      ],
      "description": "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio."
    },
    {
      "id": 2,
      "name": "Implementar listagem de tarefas",
      "status": {
        "name": "Fazendo",
        "color": "warning"
      },
      "participants": [
        {name: 'Pablo Germano', initials: 'P', color: 'primary'},
      ],
      "description": "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio."
    },
    {
      "id": 3,
      "name": "Implementar listagem de projetos",
      "status": {
        "name": "Backlog",
        "color": "primary"
      },
      "participants": [
        {name: 'Pablo Germano', initials: 'P', color: 'primary'},
        {name: 'João Zica', initials: 'J', color: 'primary'},
      ],
      "description": "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio.",
    },
    {
      "id": 4,
      "name": "Implementar kamban de tarefas",
      "status": {
        "name": "Backlog",
        "color": "primary"
      },
      "participants": [
        {name: 'Rudy Stone', avatar: '/media/avatars/300-1.jpg'},
        {name: 'Lucão', initials: 'L', color: 'primary'},
      ],
      "description": "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio."
    },
    {
      "id": 5,
      "name": "CRUD de pessoas",
      "status": {
        "name": "Entregue",
        "color": "success"
      },
      "participants": [
        {name: 'Emma Smith', avatar: '/media/avatars/300-6.jpg'},
        {name: 'Rudy Stone', avatar: '/media/avatars/300-1.jpg'},
        {name: 'Susan Redwood', initials: 'S', color: 'primary'},
      ],
      "description": "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio."
    },
  ]
}

const taskById = (id: ID) => {
  const task = tasks.data.find(obj => obj.id === id);
  return task
}

const getTasks = (query: string): Promise<TasksQueryResponse> => {
  return axios
    .get(`${GET_TASKS_URL}?${query}`)
    .then((d: AxiosResponse<TasksQueryResponse>) => d.data)
}

const getTaskById = (id: ID): Promise<Task | undefined> => {
  return axios
    .get(`${TASK_URL}/${id}`)
    .then((response: AxiosResponse<Response<Task>>) => response.data)
    .then((response: Response<Task>) => response.data)
}

const createTask = (task: Task): Promise<Task | undefined> => {
  return axios
    .put(TASK_URL, task)
    .then((response: AxiosResponse<Response<Task>>) => response.data)
    .then((response: Response<Task>) => response.data)
}

const updateTask = (task: Task): Promise<Task | undefined> => {
  return axios
    .post(`${TASK_URL}/${task.id}`, task)
    .then((response: AxiosResponse<Response<Task>>) => response.data)
    .then((response: Response<Task>) => response.data)
}

const deleteTask = (taskId: ID): Promise<void> => {
  return axios.delete(`${TASK_URL}/${taskId}`).then(() => {})
}

const deleteSelectedTasks = (taskIds: Array<ID>): Promise<void> => {
  const requests = taskIds.map((id) => axios.delete(`${TASK_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {getTasks, deleteTask, deleteSelectedTasks, getTaskById, createTask, updateTask, tasks, taskById}
