import {useState, useEffect} from 'react'
import {Link} from 'react-router-dom'
import {useIntl} from 'react-intl'
import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
// import {TasksListHeader} from './components/header/TasksListHeader'
import {TasksTable} from './table/TasksTable'
import {KTCard, KTSVG} from '../../../../../_metronic/helpers'
// import {PageToolbar} from '../../../../../_metronic/layout/core'
import {TaskModal} from '../../../../../_metronic/partials/modals/task/TaskModal'
import { Button } from 'react-bootstrap'

const TasksList = () => {
  const intl = useIntl()
  const [showTaskModal, setShowTaskModal] = useState<boolean>(false)
  const {itemIdForUpdate, taskIdForUpdate, setTaskIdForUpdate} = useListView()
  const {setItemIdForUpdate} = useListView()
  
  useEffect(() => {
    if(taskIdForUpdate || taskIdForUpdate === null) {
      setShowTaskModal(true)
    }
  }, [taskIdForUpdate])

  return (
    <>
      <KTCard>
        <div className='d-flex justify-content-between align-items-center mb-10'>
          <div className='fs-2 fw-bold'>{intl.formatMessage({id: 'GENERAL.MY_TASKS'})}</div>
          <div className='d-flex align-items-center gap-3 gap-lg-3'>
          <div className='d-flex align-items-center'>
             {/* begin::Search */}
             <div className='d-flex align-items-center position-relative my-1'>
              <KTSVG
                path='/media/icons/duotune/general/gen021.svg'
                className='svg-icon-1 position-absolute ms-3'
              />
              <input
                type='text'
                data-kt-project-table-filter='search'
                className='form-control form-control-sm form-control-solid w-md-250px ps-11 bg-transparent'
                placeholder={intl.formatMessage({id: 'TOOLBAR.SEARCH_TASK'})}
              />
          
          </div>
          {/* end::Search */}
          </div>
          <Link
              className='btn btn-primary btn-sm w-fit-content py-2 px-6 d-none d-md-block'
              to={'#'}
              onClick={() => setShowTaskModal(true)}
            >
              {intl.formatMessage({id: 'TOOLBAR.NEW_TASK'})}
            </Link>
            <Button
              className='btn btn-primary btn-sm w-fit-content py-2 d-block d-md-none'
              onClick={() => setShowTaskModal(true)}
            >
              <KTSVG
                path='/media/icons/duotune/general/gen055.svg'
                className='svg-icon svg-icon-2'
              />
            </Button>
            </div>
        </div>
        <TasksTable />
      </KTCard>
      <TaskModal
        show={showTaskModal}
        handleClose={() => {setShowTaskModal(false); setTaskIdForUpdate(undefined);}}
        taskId={taskIdForUpdate}
      />
    </>
  )
}

const TasksListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <TasksList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {TasksListWrapper}
